.footer {
    flex: 1;
    width: 100%;
    flex-direction: column;

    display: flex;
    align-items: center;
    justify-content: center;

    @media screen and (max-width: 768px) {
        flex-direction: column;
        padding-bottom: 320px;
    }
    @media screen and (max-width: 600px) {
        flex-direction: column;
        padding-bottom: 200px;
    }
    @media screen and (max-width: 500px) {
        flex-direction: column;
        padding-bottom: 150px;
    }
    @media screen and (max-width: 450px) {
        flex-direction: column;
        padding-bottom: 170px;
    }
    @media screen and (max-width: 410px) {
        flex-direction: column;
        padding-bottom: 80px;
    }

    @media screen and (max-width: 370px) {
        flex-direction: column;
        padding-bottom: 0px;
    }
}

.footer h2 {
    padding-top: 20px;
     padding-bottom: 1rem; 
    }

.footer p {
    font-size: 1.2rem;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;

    img { //coffee mug + speech bubble icons
        width: 30px;
        height: 30px;
        margin: 0 0.7rem;
    }

    @media screen and (max-width: 600px) {
        flex-direction: column;
    }
    @media screen and (max-width: 450px) {
        font-size: 1rem;

        img { //coffee mug + speech bubble icons
            width: 22px;
            height: 22px;
            margin: 0 0.7rem;
        }
    }
}

.footer-socials { // mail | github | linkedin icons
    width: 25%;
    display: flex;    
    justify-content: space-evenly;
    align-items: center;
    flex-wrap: wrap;
    // margin: 2rem 2rem 2rem;
    margin-top: 10px;

    .mail-icon {
        svg {
            scale: 1.05;
        }
    }
    .linkedin-icon {
        svg {
            scale: 0.95;
        }
    }
    .github-icon {
        svg {
            scale: 1;
        }
    }

    .footer-social-icon { 
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        margin: 0.25rem 0;
        padding: 1rem;
        border-radius: 50%;
        cursor: pointer;
        transition: all 0.3s ease-in-out;

        width: 60px;
        height: 60px;
        border: 1px solid var(--lightGray);

        svg {
            width: 35px;
            height: 35px;
            color: var(--primary-dark);
            transition: all 0.3s ease-in-out;
        }

        &:hover { 
            box-shadow: 0 0 25px #fef4f5;

            svg {
                color: var(--secondary);
                transform: scale(1.05);
            }
        }
    
        @media screen and (min-width: 2000px) {
            svg {
              width: 40px;
              height: 40px;
            }
        }

        @media screen and (max-width: 450px) {
            svg {
              width: 28px;
              height: 28px;
            }
        }
    }

    @media screen and (max-width: 900px) {
        width: 40%;
    }

    @media screen and (max-width: 620px) {
        width: 60%;
    }
    @media screen and (max-width: 340px) {
        width: 80%;
    }
}

.footer-form {
    width: 50%;
    flex-direction: column;
    margin: 0.5rem 2rem;

    div {
        background-color: var(--primary-light);
        width: 100%;
        margin: 0.5rem 0;
        border-radius: 10px;
        transition: all 0.3s ease-in-out;
        cursor: pointer;

        input, textarea {
            width: 100%;
            padding: 0.7rem;
            border: none;
            border-radius: 7px;
            background-color: var(--primary-light);
            color: var(--secondary);
            outline: none;
            font-size: 1rem;
        }

        textarea { height: 170px; }

        &:hover { box-shadow: 0 0 25px var(--offwhite); }
    }

    button {
        background-color: var(--primary-dark);
        color: var(--white);
        font-size: 0.85rem;
        font-weight: 500;
        border: none;
        border-radius: 10px;
        outline: none;
        // margin: 2rem 0 0 0;
        margin-top: 10px;
        padding: 0.8rem 1.5rem;
        transition: all 0.3s ease-in-out;
        cursor: pointer;
      
        svg { margin-left: 1rem; }

        &:hover {
            box-shadow: 0 0 25px  #fef4f5;
            background-color: var(--secondary);
            color: var(--offwhite);
            transform: scale(1.01);
        }

        @media screen and (max-width: 450px) {
            font-size: 0.8rem;
            padding: 0.6rem;
        }  
    }

    @media screen and (max-width: 768px) {
        width: 80%;
        margin: 1rem 0;
    }
    @media screen and (max-width: 450px) {
        div {
            input, textarea {
          
            padding: 0.5rem;
    
            font-size: 0.8rem;
        }
    }
    }
}