
/* font imports */
@import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Bruno+Ace+SC&display=swap');


@import url('https://fonts.googleapis.com/css2?family=Bruno+Ace&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bruno+Ace&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Gugi&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Orbitron:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=VT323&display=swap');

@font-face {
  font-family: 'Nulshock';
  src: url('./assets/fonts/nulshock.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Good Times';
  src: url('./assets/fonts/goodtimes-regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Terminator';
  src: url('./assets/fonts/terminator.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

:root {
  /* font variables */
  --font-base: 'DM Sans', 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
  /* --font-editorial: 'Bruno Ace SC', 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif; */
  /* --font-editorial: 'Bruno Ace', cursive; */
   --font-nav: 'Nulshock', 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
   /* --font-editorial: 'Terminator','Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif; */
   /* --font-editorial: 'Good Times','Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif; */
  --font-editorial: 'Nulshock','Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
  --font-typewriter: 'VT323', monospace;
  /* color variables */
  --offwhite: #edf2f8;
  --primary-light: #f4eae9;
  --primary-dark: #916265;
  --primary-deep: #682a2e;
  --primary-dark-a:rgba(104, 42, 46, 0.85);
  --secondary: #313bac;
  --secondary-a: rgba(48, 58, 171, 0.816);
  --secondary-dark: #4C2777;
  --yellow: #fef071;

 

  



  --primary: #edf2f8;
  /* --secondary: #502470; */
  --black: #030303;
  --light-gray: #e4e4e4;
  --gray: #6b7688;
  --gray-a: rgba(108, 119, 137, 0.55);
  --brown: #46364a;
  --white: #ffffff;

  --darkgreen-a: rgba(9, 59, 36, 0.794);
  --lightblue: #8ee4ef;

  /* animations */
  --typewriterSpeed: 3s; 
}


.bg-white {
  background-color: var(--white);

}

.bg-primary {
  background-color: var(--primary-light);
}

/* targeting all elements */

*, *::before, *::after{
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
}

*::selection {
  background: var(--primary-light);
}
*::-moz-selection {
  background: var(--primary-light);
}

ul, li {
  list-style: none;
}

a {
  text-decoration: none;
}

input,
textarea,
button,
a,
p,
div {
  font-family: var(--font-base)
}