

.experience {
    display: flex;
    justify-content: center;
    align-items: center;
    
    h2 {
        padding-bottom: 55px;
    }
}

.experiences {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  
    @media screen and (max-width: 900px) {
      margin-top: 2rem;
    }

    .experience-container {
        width: 100%;
        @media screen and (max-width: 950px) {
         padding-left: 50px; 
        
        }
        @media screen and (max-width: 600px) {
         padding-left: 20px; 
        
        }
       
    }
    
}


.experience-year {
    width: 25%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-right: 20px;
 
     p {
       font-weight: 500;
       font-size: 1.1rem;
       color: var(--gray);
       text-align: right;
     }
 
     .experience-img {
         width: 70%;
         display: flex;
         align-items: center;
         justify-content: center;
         img {
             width: 40%;
          
         
         }
 
     }
   
     @media screen and (max-width: 1250px) {
        .experience-img {
            img {
                width: 55%;
            }
        }
     }
     @media screen and (max-width: 950px) {
        width: 100%;
        align-items: flex-start;
        justify-content: flex-start;

        .experience-img {
            align-items: flex-start;
            justify-content: flex-start;
            img {
                width: 15%;
            }
        }
     }
     @media screen and (max-width: 800px) {
        
        img {
            width: 18% !important;
        }

     }
     @media screen and (max-width: 550px) {
        
        img {
            width: 25% !important;
        }
     }
     @media screen and (max-width: 410px) {
        
        img {
            width: 35% !important;
        }
     }
     @media screen and (max-width: 350px) {
        
        img {
            width: 40% !important;
        }
     }
 }
 
 
.experience-item {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
   
    
    @media screen and (max-width: 950px) {
       flex-direction: column;
    }


    
}




.experience-works {
    flex: 1;


    .experience-work {
        width: 65%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        margin-bottom: 1rem;
    
        h3 {
         font-size: 1.2rem;
         color: var(--primary-dark)
         
         
        }

        h4 {
            font-weight: 500;
            font-size: 1.1rem;
            color: var(--primary-dark);
        }
    
        p {
          font-weight: 400;
          color: var(--gray);
          margin-top: 5px;
          font-size: 0.9rem;
          text-align: justify;
        }

        @media screen and (max-width: 950px) {
            width: 93%;

        }
        @media screen and (max-width: 400px) {
          h3, h4 {
            font-size: 1rem;
          }
        }


      }

      @media screen and (max-width: 950px) {
        width: 100%;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-bottom: 8px;
        border-bottom: 1px solid var(--gray-a);
     
            div {
                display: flex;
                align-items: flex-start;
                justify-content: flex-start;
                
                div {
                    display: flex;
                    align-items: flex-start;
                    justify-content: flex-start;

                    h3, h4 {
                        text-align: left;
                       
                       
                    }

                    p {
                        @media screen and (max-width: 400px) {
                            font-size: 1rem;

                        }
                    }
                   
                }
            }
        }

       
        
     
  
}

.experience-item.rev {
 
    flex-direction: row-reverse;
  
    .experience-year {
        flex-direction: column;
        align-items: center;


        @media screen and (max-width: 1250px) {
            .experience-img {
                img {
                    width: 55%;
                }
            }
         }
         @media screen and (max-width: 950px) {
            width: 100%;
            align-items: flex-start;
            justify-content: flex-start;
    
            .experience-img {
                align-items: flex-start;
                justify-content: flex-start;
                img {
                    width: 15%;
                }
            }
         }
    }
    .experience-works {
        padding-right: 20px;
       div {
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
        div {
            h3, h4 {
                text-align: right;
               
            }
            p {align-items: center; 
                text-align:right;}
        }
        
    }
    }

    
    @media screen and (max-width: 950px) {
        flex-direction: column;
        .experience-works {
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            
            div {
                align-items: flex-start;
                justify-content: flex-start;



            .experience-work {
                    flex-direction: column;
                    align-items: flex-start;
                    justify-content: flex-start;

                    h3, h4 {
                        text-align: left;
                    }
                    
                    p {
                        text-align: justify;
                    }
            }

            }

        }
        
     }
    
}


.exp-img-3 {
    padding: 12px;
    margin-top: -5px;

    @media screen and (max-width: 1330px) {
        padding: 8px;
        margin-top: -3px;
    }
}
.exp-img-2 {
    padding: 30px;
    margin-top: -25px;

    @media screen and (max-width: 1550px) {
        padding: 22px;
        margin-top: -20px;

    }

    @media screen and (max-width: 1330px) {
        padding: 18px;
        margin-top: -15px;
    }
    @media screen and (max-width: 950px) {
       margin-left: -10px;
       margin-bottom: -10px;
    }



}