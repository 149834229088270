.about {
    flex: 1;
    width: 100%;
    display: flex;
 
}

.about-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;


}

.about-intro {
    width: 40%;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;

    .about-introduction {
        display: flex;
        flex-direction: column;
        align-items: center;
    }


    .section-heading {
        margin-bottom: 20px;
        text-align: center;
        width: 85%;
        text-align: left;
    }
  
    .about-avatar {
        width: 48%;

        img {
            width: 100%;
            border-radius: 20%;
        }

        @media screen and (max-width: 1800px) {
            width: 55%;

        }
        @media screen and (max-width: 1750px) {
            width: 60%;

        }
        @media screen and (max-width: 1700px) {
            width: 70%;

        }
        @media screen and (max-width: 1650px) {
            width: 75%;

        }
        @media screen and (max-width: 1600px) {
            width: 50%;

        }
        @media screen and (max-width: 1550px) {
            width: 60%;


        }
        @media screen and (max-width: 1250px) {
            
            width: 75%;
        }
        @media screen and (max-width: 1150px) {
            
            width: 65%;
        }
        @media screen and (max-width: 1050px) {
            
            width: 75%;
        }
        @media screen and (max-width: 920px) {
            
            width: 90%;
        }
        @media screen and (max-width: 820px) {
            
            width: 100%;
        }
        @media screen and (max-width: 650px) {
            
            width: 30%;
            margin: 10px;
        }
        @media screen and (max-width: 500px) {
            
            width: 40%;
            margin: 10px;
        }
        @media screen and (max-width: 350px) {
            
            width: 48%;
            margin: 10px;
        }

      


    }
    
    .about-intro-text {
        flex-direction: column;

        p {
            font-size: 1rem;
            text-align: justify;
            width: 85%;
        }

        @media screen and (max-width: 800px) {
            
           p {
            font-size: 0.85rem;
           }
        }
        @media screen and (max-width: 650px) {
            
           p {
            width: 95%;
           }
        }
    }


    @media screen and (max-width: 1600px) {
        width: 48%;

    }
    @media screen and (max-width: 1450px) {
        width: 50%;    

    }
    @media screen and (max-width: 1400px) {
        width: 52%;    

    }
    @media screen and (max-width: 1400px) {
        width: 55%;    

    }
    @media screen and (max-width: 1400px) {
        width: 65%;    

    }
    @media screen and (max-width: 1075px) {
        width: 68%;    

    }
    @media screen and (max-width: 1000px) {
        width: 72%;    

    }
    @media screen and (max-width: 750px) {
        width: 80%;    

    }

    @media screen and (max-width: 650px) {
        width: 95%;
        flex-direction: column-reverse;
        align-items: center;
        justify-content: center;
        h2 {
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
        }
    }


}

.profile {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-wrap: wrap;
    margin-top: 1rem;

    @media screen and (max-width: 650px) {
        flex-direction: column;  
        align-items: center;
        justify-content: center;
        padding-left: 2rem;
        padding-right: 2rem;

    }
    @media screen and (max-width: 500px) {
   
        padding-left: 0rem;
        padding-right: 0rem;

    }
    
}

.profile-item {
    width: 15%;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    margin: 1.5rem;
   

    img {
        width: 100%;
        height: 100px;
        border-radius: 25px;
        border: 1px solid var(--primary-dark);
        object-fit: cover;
        background-color: rgb(36, 33, 33);
    }

    h2 {
        color: var(--primary-dark);
        text-align: center;

    }

    p {
        text-align: justify;
        font-size: 0.95rem;
  
    }

    @media screen and (max-width: 1600px) {
       width: 14%;

    }

    @media screen and (max-width: 1450px) {
        width: 15%;  
    

    }
    @media screen and (max-width: 1300px) {
        width: 17%;    

    }
    @media screen and (max-width: 1150px) {
        width: 19%; 
   

    }
    @media screen and (max-width: 1075px) {
        width: 20%;    

    }
    @media screen and (max-width: 1000px) {
        width: 22%;    

    }
    @media screen and (max-width: 750px) {
        margin: 1rem;
        width: 25%;    

    }
    @media screen and (max-width: 650px) {
        width: 90%; 
        display: flex;
        align-items: center;
        justify-content: center;
        img {
            width: 25%;
        }
    }

    @media screen and (max-width: 400px) {
        img { width: 35%; }
    }
    @media screen and (max-width: 400px) {
        img { width: 45%; }
    }
    @media screen and (max-width: 300px) {
        img { width: 55%; }
    }

   
}