.skills-section {
    flex: 1;
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;

}

.skills-container {
    width: 80%;
    margin-top: 3rem;
    display: flex;
    // flex-direction: row;
    flex-direction: column;  
    @media screen and (max-width: 900px) {
      width: 100%;
      flex-direction: column;
    }
}

.skills-list {  
    flex: 1;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
  
  
    @media screen and (max-width: 900px) {
      margin-right: 0;
      justify-content: center;
      align-items: center;
    }
}

.skills-item {
  
    flex-direction: column;
    text-align: center;
    margin: 1rem;
    transition: all 0.3s ease-in-out;
  
    div {
      width: 90px;
      height: 90px;
      border-radius: 50%;
      background-color: #fef4f5;
  
      img {
        width: 50%;
        height: 50%;
      }
  
      &:hover {
        box-shadow: 0 0 25px #fef4f5;
      }
  
      @media screen and (min-width: 2000px) {
        width: 150px;
        height: 150px;
      }
  
      @media screen and (max-width: 450px) {
        width: 70px;
        height: 70px;
      }
    }

  p {
    font-weight: 500;
    margin-top: 0.5rem;
  }

  @media screen and (min-width: 2000px) {
    margin: 1rem 2rem;

    p {
      margin-top: 1rem;
    }
  }
}




// adjusting size of icons
#mongoose-icon { img { scale: 1.4 } }
#mongodb-icon { img { scale: 1.2 } }
#react-icon { img { scale: 1.1 } }
#redux-icon { img { scale: 0.95 } }
#express-icon { img { scale: 1.05 } }
#bootstrap-icon { img { scale: 1.05 } }
#ruby-icon { img { 
    scale: 0.95;
    margin-right: 5px;
} }
#babel-icon { img { scale: 1.2 } }
#npm-icon { img { scale: 1.1 } }
#tailwind-icon { img { scale: 1.15 } }
#heroku-icon { img { scale: 0.95 } }
#firebase-icon { img { scale: 1.05 } }
#firestore-icon { img { scale: 1.15 } }
#netlify-icon { img { scale: 1.05 } }
#vite-icon { img { 
    scale: 1.05;
    margin-top: 5px;
} }

//skills icons changing color on hover
#html-icon:hover {
    p { color: #e34b24; }
    img { content: url('./../../assets/icons/icons_skills/html_color.svg') }
}

#css-icon:hover {
    p { color: #006db8; }
    img { content: url('./../../assets/icons/icons_skills/css_color.svg') }
}

#sass-icon:hover {
    p { color: #cf6b9e; }
    img { content: url('./../../assets/icons/icons_skills/sass_color.svg') }
}

#javascript-icon:hover {
    p { color: black; }
    img { content: url('./../../assets/icons/icons_skills/javascript_color.svg') }
}

#nodejs-icon:hover {
    p { color: #529f41; }
    img { content: url('./../../assets/icons/icons_skills/nodejs_color.svg') }
}

#react-icon:hover {
    p { color: #0da3d4; }
    img { content: url('./../../assets/icons/icons_skills/react_color.svg') }
}

#redux-icon:hover {
    p { color: #764ABC; }
    img { content: url('./../../assets/icons/icons_skills/redux_color.svg') }
}

#express-icon:hover {
    p { color: #c93d30; }
    img { content: url('./../../assets/icons/icons_skills/express_color.svg') }
}

#mongodb-icon:hover {
    p { color: #46a438; }
    img { content: url('./../../assets/icons/icons_skills/mongodb_color.svg') }
}

#mongoose-icon:hover {
    p { color: #9e0303; }
    img { content: url('./../../assets/icons/icons_skills/mongoose_color.svg') }
}

#ruby-icon:hover {
    p { color: #cf6b9e; }
    img { content: url('./../../assets/icons/icons_skills/ruby_color.svg') }  
}

#rails-icon:hover {
    p { color: #cd0000; }
    img { content: url('./../../assets/icons/icons_skills/rails_color.svg') }  
}

#postgresql-icon:hover {
    p { color: #1d649e; }
    img { content: url('./../../assets/icons/icons_skills/postgresql_color.svg') }  
}

#tailwind-icon:hover {
    p { color: #44a8b3; }
    img { content: url('./../../assets/icons/icons_skills/tailwind_color.svg') }  
}

#bootstrap-icon:hover {
    p { color: #7E13F8; }
    img { content: url('./../../assets/icons/icons_skills/bootstrap_color.svg') }  
}

#postman-icon:hover {
    p { color: #f37036; }
    img { content: url('./../../assets/icons/icons_skills/postman_color.svg') }  
}

#heroku-icon:hover {
    p { color: #430098; }
    img { content: url('./../../assets/icons/icons_skills/heroku_color.svg') }  
}
#openai-icon:hover {
    p { color: black; }
    img { content: url('./../../assets/icons/icons_skills/openai_color.svg') }  
}
#vite-icon:hover {
    p { color: #9467FE; }
    img { content: url('./../../assets/icons/icons_skills/vite_color.svg') }  
}

#netlify-icon:hover {
    p { color: #32b5ba; }
    img { content: url('./../../assets/icons/icons_skills/netlify_color.svg') }  
}

#passport-icon:hover {
    p { color: #000; }
    img { content: url('./../../assets/icons/icons_skills/passport_color.svg') }  
}

#jwt-icon:hover {
    p { color: #D63AFF}
    img { content: url('./../../assets/icons/icons_skills/jwt_color.svg') }  
}

#npm-icon:hover {
    p { color: #cb3837}
    img { content: url('./../../assets/icons/icons_skills/npm_color.svg') }  
}

#yarn-icon:hover {
    p { color: #368FB9}
    img { content: url('./../../assets/icons/icons_skills/yarn_color.svg') }  
}

#babel-icon:hover {
    p { color: #000}
    img { content: url('./../../assets/icons/icons_skills/babel_color.svg') }  
}

#firebase-icon:hover {
    p { color: #e99619}
    img { content: url('./../../assets/icons/icons_skills/firebase_color.svg') }  

}

#firestore-icon:hover {
    p { color: #e99619}
    img { content: url('./../../assets/icons/icons_skills/firestore_color.svg') } 
}

#git-icon:hover {
    p { color: #F34F29}
    img { content: url('./../../assets/icons/icons_skills/git_color.svg') }  
}

#github-icon:hover {
    p { color: #4C2777}
    img { content: url('./../../assets/icons/icons_skills/github_color.svg') }  
}