
#home {
    background-color: black;
}

.header {
    flex: 1;
    width: 100%;
    height: 100%;
    flex-direction: column;
    padding: 0;
    margin: 0;
   

    .h-text::selection {
        background: var(--primary-dark-a); 
    }
    .h-text::-moz-selection {
        background: var(--primary-dark-a);
    }
    .p-text::selection {
        background: var(--primary-dark-a);
    }
    .p-text::-moz-selection {
        background: var(--primary-dark-a);
    }
      

    .header-info {
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 100%;
        width: 100%;
        position: absolute;
        top: 30%;
        opacity: 0;
        transform: translateX(-25rem);
        animation: fadeInLtR 2s ease forwards;

    }

    @media screen and (max-width: 620px) {
        .header-info {
            top: 20%
        }
    }



.header-text {
    position: absolute;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: var(--white);
 
    .header-text-container {
        flex-direction: column;
        color: var(--white);
        width: 100%;
        width: 100%;
       

        .greeting {
            font-family: var(--font-typewriter);
            color: var(--yellow);
            width: 100%;
           
            font-size: 2.5rem;
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;

            span {
                font-size: 2.5rem;
                padding-right: 1rem;
    
            }

        }

        @media screen and (max-width: 800px) {
            .greeting {font-size: 1.75rem;}
        }

        .intro {
            font-family: var(--font-editorial);  
            display: flex;      
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 100%;
            margin-top: 1rem;
            
   

            .header-name {
                position: relative;
                text-align: center;
                color: var(--yellow);
                margin-bottom: 5px;
                font-size: 3.2rem;
                text-shadow: 
                0.05em 0 0 rgba(255, 0, 0, .75),
                -0.025em -0.05em 0 rgba(0, 255, 0, .75),
                0.025em 0.05em 0 rgba(0, 0, 255, .75);
               animation: header-name 500ms infinite;

               span {
                position: absolute;
                top: 0;
                left: 0;
               }

               span:first-child {
                animation: header-name 650ms infinite;
                clip-path: polygon(0 0, 100% 0, 100% 45%, 0 45%);
                transform: translate(-0.025em, -0.0125em);
                opacity: 0.8;
               }
               span:last-child {
                animation: header-name 375ms infinite;
                clip-path: polygon(0 80%, 100% 20%, 100% 100%, 0 100%);
                transform: translate(0.0125em, 0.025em);
                opacity: 0.8;
               }
            }

            @keyframes header-name {
                0% {
                    text-shadow: 
                    0.05em 0 0 rgba(255, 0, 0, .75),
                    -0.05em -0.025em 0 rgba(0, 255, 0, .75),
                    -0.025em 0.05em 0 rgba(0, 0, 255, .75);
                }
                14% {
                    text-shadow: 
                    0.05em 0 0 rgba(255, 0, 0, .75),
                    -0.05em -0.025em 0 rgba(0, 255, 0, .75),
                    -0.025em 0.05em 0 rgba(0, 0, 255, .75);
                }
                15% {
                    text-shadow: 
                    -0.05em -0.025em 0 rgba(255, 0, 0, .75),
                    0.025em 0.025em 0 rgba(0, 255, 0, .75),
                    -0.05em -0.05em 0 rgba(0, 0, 255, .75);
                }
                49% {
                    text-shadow: 
                    -0.05em -0.025em 0 rgba(255, 0, 0, .75),
                    0.025em 0.025em 0 rgba(0, 255, 0, .75),
                    -0.05em -0.05em 0 rgba(0, 0, 255, .75);
                }
                50% {
                    text-shadow: 
                    0.025em 0.05em 0 rgba(255, 0, 0, .75),
                    0.05em 0 0 rgba(0, 255, 0, .75),
                    0 -0.05em 0 rgba(0, 0, 255, .75);
                }
                99% {
                    text-shadow: 
                    0.025em 0.05em 0 rgba(255, 0, 0, .75),
                    0.05em 0 0 rgba(0, 255, 0, .75),
                    0 -0.05em 0 rgba(0, 0, 255, .75);
                }
                100% {
                    text-shadow: 
                    -0.025em 0 0 rgba(255, 0, 0, .75),
                    -0.025em -0.025em 0 rgba(0, 255, 0, .75),
                    -0.025em -0.05em 0 rgba(0, 0, 255, .75);
                }
           
            
                
            }

            .job-title {
                font-family: var(--font-typewriter);
                position: relative;
                flex-direction: column;
                align-items: center;
                color: var(--yellow);
                text-align: right;
                font-size: 3rem;
                font-weight: 700;
                text-align: center;
                width: max-content;
              
             
            }

            .job-title::before,
            .job-title::after {
                content: '';
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
            }

            .job-title::before {
                background-color: black;
                /* Full-Stack Developer - 20 characters */
                animation: typewriter var(--typewriterSpeed) steps(20) 1.75s forwards;

            }
            .job-title::after {
               width: .08em;
               background-color: var(--yellow);
               height: 70%;
               margin-top: 0.2em;

               /* Full-Stack Developer - 20 characters */
               animation: typewriter var(--typewriterSpeed) steps(20) 1.75s forwards,
               blink 750ms steps(20) infinite; 

            }

            @keyframes typewriter {
                to {
                    left: 100%;
                }
            }

            @keyframes blink {
                to {
                    background-color: transparent;
                }

            }

            @keyframes fadeInLtR {
                to {
                    opacity: 1;
                    transform: translateX(0);
                }

            }
        



            @media screen and (max-width: 800px) {

                h1 {font-size: 2.5rem;}
                .job-title {font-size: 2rem;}
               

            }

            @media screen and (max-width: 600px) {
                h1 {font-size: 1.8rem;
                    
                }
            }

            @media screen and (max-width: 400px) {

                h1 {font-size: 1.8rem;}
                .job-title {
                    font-size: 1.7rem;
                   
                }

            }
        }
    }
}




.contact-icons {
    position: absolute;
    bottom: 8px;
    left: 5px;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    flex-direction: row;
    gap: 5px;
    
  
    div {
      width: 40px;
      height: 40px;
      border-radius: 50%;
    
      border: 2px solid var(--gray-a);
      
  
      display: flex;
      justify-content: center;
      align-items: center;
      align-items: center;
      transition: all 0.3s ease-in-out;
  
      svg {
        width: 25px;
        height: 25px;
        padding-top: 3px;
        color: var(--gray-a);
        transition: all 0.3s ease-in-out;

      }

      .contact-icon-mail {
        svg {
            scale: 1.2;
            
        }
        }

  
      &:hover {
        border: 2px solid var(--gray);
       
        svg { 
          color: var(--gray);
        }
  
      }
  
      @media screen and (min-width: 2000px) {
        width: 70px;
        height: 70px;
  
        margin: 0.5rem 0;
  
        svg {
          width: 30px;
          height: 30px;
        }
      }
    }
  }
}

.particles-container {
    width: 100%;
    height: 100%;
}

@media (prefers-reduced-motion: reduce) {
    *,
    ::before,
    ::after {
        animation-delay: -1ms !important;
        animation-duration: 1ms !important;
        animation-iteration-count: 1 !important;
        background-attachment: initial !important;
        scroll-behavior: auto !important;
        transition-duration: 0s !important;
        transition-delay: 0s !important;
    }
}