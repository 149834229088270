
.app {
  background-color: var(--offwhite);
  font-family: var(--font-base);
 
}

.app-cont {
  min-height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: row;
  scroll-snap-type: y mandatory;
  scroll-behavior: smooth;
  overflow-y: auto;
}

.app-wrapper {
  flex: 1;
  width: 100%;
  flex-direction: column;
  padding: 4rem 2rem;

  @media screen and (max-width: 450px) {
    padding: 4rem 1rem 2rem;
  }
}

.flex {
  display: flex;
  justify-content: center;
  align-items: center;
}

.h-text {
  font-size: 2.75rem;
  font-weight: 800;
  text-align: center;
  color: var(--primary-dark);
  text-transform: capitalize;

  span {
    color: var(--secondary);
  }

  @media screen and (min-width: 2000px) {
    font-size: 4rem;
  }

  @media screen and (max-width: 450px) {
    font-size: 2rem;
  }
}

.section-heading {
  font-family: var(--font-editorial);
  font-size: 2.75rem;
  font-weight: 800;
  text-align: center;
  color: var(--primary-dark);
  @media screen and (max-width: 550px) {
    font-size: 2rem;
  }
}

.p-text {
  font-size: 0.8rem;
  text-align: left;
  color: var(--gray);
  line-height: 1.5;

  @media screen and (min-width: 2000px) {
    font-size: 1.75rem;
  }
}


.bold {
  font-size: 1rem;
  font-weight: 800;
  color: var(--black);
  text-align: left;

  @media screen and (min-width: 2000px) {
    font-size: 2rem;
  }

  @media screen and (max-width: 450px) {
    font-size: 0.9rem;
  }
}

.nav-dots {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 1rem;
  position: fixed;
  top: 50%;
  transform: translate(-50%, -50%);
  right: -2%;

  .nav-dot {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #cbcbcb;
    margin: 0.5rem;
    transition: background-color 0.2s ease-in-out;

    &:hover {
      background-color: var(--secondary)!important;
    }

    @media screen and (min-width: 2000px) {
      width: 20px;
      height: 20px;
    }
  }
}

@media screen and (max-width: 500px) {
  .nav-dots,
  .contact-icons {
    display: none;
  }
}