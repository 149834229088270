.navbar {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.9rem 2rem;
  
    // background: rgba(255, 255, 255, 0.25);
    background: var(--primary-dark-a);
    backdrop-filter: blur(4px);
    -webkit-backdrop-filter: blur(4px);
    // border: 1px solid rgba(255, 255, 255, 0.18);
    position: fixed;
    z-index: 2;
}

.navbar-logo {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    img {
        width: 90px;
        height: 20px;

    @media screen and (min-width: 2000px) {
      width: 180px;
      height: 40px;
    }
  }
}

.nav-links {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
   
   

    li {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0rem 2rem;
        margin-bottom: 1.75px;
        cursor: pointer;
        flex-direction: column;
        position: relative;
       
       

        div {
            width: 5px;
            height: 5px;
            background: transparent;
            border-radius: 50%;
            margin-bottom: 5px;
            transition: all 0.3s ease-in-out;
        }

        a {
            // color: var(--gray);
            // text-transform: uppercase;
            // font-weight: 500;
            font-family: var(--font-nav);
            color: var(--primary-light);
            flex-direction: column;
            font-weight: 400;
            font-size: 1.25rem;
            transition: 0.4s ease-in-out;
            position: relative;
            padding: 0 10px;

            &:hover {
               color: var(--white);
            }
        }
    }

    li::after {
        content: "";
        position: absolute;
        background-color: var(--white);
        height: 1.5px;
        width: 0;
        left: 0;
        bottom: -2px;
        transition: all 0.5s ease;

    }

    li:hover::after {
        width: 100%;

    }

    @media screen and (max-width: 1150px) {
        display: none;
    }
}

.hamburger {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    position: relative;
    
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--primary-light);

    svg { // hamburger icon
        width: 70%;
        height: 70%;
        color: var(--primary-dark);
    }

    div { // mobile menu
        position: fixed;
        top: 0;
        bottom: 0;
        right: 0;
        z-index: 5;
        padding: 1rem;

        width: 50%;
        height: 100vh;

        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
        flex-direction: column;

        background-color: var(--primary-light);
        box-shadow: 0px 0px 20px rgba(168, 168, 168, 0.15);

        /**
        background: url();
        background-size: cover;
        background-repeat: repeat;
         **/

        svg { // close icon
            width: 35px;
            height: 35px;
            color: var(--primary-dark);
            margin: 0.5rem 1rem;
        }

        ul {
            margin: 0;
            padding: 0;
            height: 100%;
            width: 100%;

            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            flex-direction: column;

            li {
                margin: 1rem;

                a {
                    // text-transform: uppercase;
                    color: var(--primary-dark);
                    font-size: 1.2rem;
                    font-weight: 500;
                    transition: all 0.3s ease-in-out;

                    &:hover {
                       color: var(--primary-deep);
                    }
                }
            }
        }

        @media screen and (min-width: 1150px) {
            display: none;
        }
        @media screen and (max-width: 600px) {
            width: 60%;
        }
        @media screen and (max-width: 500px) {
            width: 70%;
        }
        @media screen and (max-width: 430px) {
            width: 80%;
        }
        @media screen and (max-width: 380px) {
            width: 100%;
        }
        @media screen and (max-width: 299px) {
            width: 110%;
            li {
                a {margin-left: 2rem;}}
        }
    }

    @media screen and (min-width: 1151px) {
        display: none;
    }
}